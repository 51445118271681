<h2 md-dialog-title>Have you saved your work?</h2>
<md-dialog-content class="mat-typography" class="dialog-content-class">
  <p fxFlex="100%">
    Click cancel and submit your answer or comments before you refresh. Your work will be lost if you haven't saved it yet.
  </p>
  <p fxFlex="100%" class="checkbox">
    <md-checkbox (change)="checkDontShow($event.checked)" color="accent"
      >Don't show this again</md-checkbox
    >
  </p>
</md-dialog-content>
<md-dialog-actions align="end">
  <button md-button [md-dialog-close]="false">Cancel</button>
  <button md-button [md-dialog-close]="true" cdkFocusInitial>Refresh</button>
</md-dialog-actions>
