<div class="app-about">
  <table style="width: 100%">
    <tr style="width: 100%">
      <td align="center" style="width: 50%">
        <!-- Carrousel -->
        <div class="row">
          <!-- <div class="col">
            <button md-button (click)="previousImage()"><md-icon>chevron_left</md-icon></button>
          </div> -->
          <div class="col" [@visibilityChanged]="visibility">
            <img [src]="imagesUrls[courrentImage]" />
          </div>
          <!-- <div class="col">
            <button md-button (click)="nextImage()"><md-icon>chevron_right</md-icon></button>
          </div> -->
        </div>
        <!-- end: Carrousel -->
      </td>
      <td style="width: 50%">
        <!-- Banner -->
        <div style="max-width: 450px">
          <h1 class="md-display-2" color="primary">What is Blognog?</h1>
          <p class="white-p">
            BlogNog is a qualitative research platform, developed by
            <a href="http://www.accelerantresearch.com/">
              <b>Accelerant Research</b>,
            </a>
            which helps organizations to connect with their customers or
            employees. BlogNog is used to facilitate:
          </p>
          <ul>
            <li>Bulletin-board focus groups</li>
            <li>Online ethnographic research (ethnographies)</li>
            <li>Mobile qualitative research</li>
            <li>Market Research Online Communities (MROCs)</li>
            <li>In-Depth Interviews (IDIs)</li>
          </ul>
        </div>
        <!-- end: Banner -->
      </td>
    </tr>
  </table>
  <!-- Disclaimer -->
  <div fxLayout="row" fxLayoutAlign="center center">
    <p>
      From participant recruiting and project administration to full-service
      qualitative research and insights, we are standing by to help you execute
      your next online qualitative research project.
    </p>
  </div>
  <!-- end: Disclaimer -->

  <!-- <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayout.gt-sm="column" fxFlex="100%" fxFlex.gt-sm="40%" fxLayoutAlign="center center">
      Carousel
      <div class="slider" ng2-carouselamos [width]="500" [items]="imagesUrls" [$item]="itemTemplate" [$prev]="prevTemplate" [$next]="nextTemplate" 
        (onSelectedItem)="itemSelected($event)">
      </div>

      <ng-template #prevTemplate>
        <md-icon>chevron_left</md-icon>
      </ng-template>

      <ng-template #nextTemplate>
        <md-icon>chevron_right</md-icon>
      </ng-template>

      <ng-template let-item let-i="index" #itemTemplate>
        <img class="slider-image" *ngIf="i !== selectedIndex" src="{{item}}">
      </ng-template>
    </div>
    <div fxLayout="column" fxFlex="80%" fxFlex.gt-sm="40%" fxLayoutAlign="center center" class="about-disclaimer">
      <div class="text-start">
        <h1 class="md-display-2" color="primary">What is Blognog?</h1>
        <p class="white-p">
          BlogNog is a qualitative research platform, developed by
          <a href="http://www.accelerantresearch.com/">
            <b>Accelerant Research</b>,
          </a> which helps organizations to connect with their customers or employees. BlogNog is used to facilitate:
        </p>
        <ul>
          <li>Bulletin-board focus groups</li>
          <li>Online ethnographic research (ethnographies)</li>
          <li>Mobile qualitative research</li>
          <li>Market Research Online Communities (MROCs)</li>
          <li>In-Depth Interviews (IDIs)</li>
        </ul>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="column" fxFlex="80%" fxLayoutAlign="center center">
        <p>From participant recruiting and project administration to full-service qualitative research and insights, we are
            standing by to help you execute your next online qualitative research project.</p>
    </div>
  </div>
</div>
end:Disclaimer -->
</div>
