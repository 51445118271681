<div class="landing-buttons">
  <!--Disclaimer -->
  <div
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxFlex="100%"
    fxLayoutAlign="center center"
    fxLayoutAlign.gt-sm="center start"
  >
    <div
      fxLayout="row"
      fxLayout.gt-sm="column"
      fxFlex="100%"
      fxFlex.gt-sm="20%"
      fxLayoutAlign="center center"
      class="image-button"
      (click)="openLoginModal()"
    >
      <div class="text-center">
        <img src="/assets/images/login.png" />
        <h2>LOG-IN</h2>
        <span>to your existing project</span>
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayout.gt-sm="column"
      fxFlex="100%"
      fxFlex.gt-sm="20%"
      fxLayoutAlign="center center"
      class="image-button"
      (click)="goToContact('new')"
    >
      <div class="text-center">
        <img src="/assets/images/start_orig.png" />
        <h2>START A NEW PROJECT</h2>
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayout.gt-sm="column"
      fxFlex="100%"
      fxFlex.gt-sm="20%"
      fxLayoutAlign="center center"
      class="image-button"
      (click)="goToContact('demo')"
    >
      <div class="text-center">
        <img src="/assets/images/demo_orig.png" />
        <h2>REQUEST MORE INFORMATION</h2>
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayout.gt-sm="column"
      fxFlex="100%"
      fxFlex.gt-sm="20%"
      fxLayoutAlign="center center"
      class="image-button"
      onclick="window.open('http://surveys.accelerantresearch.com/s3/ARDC', '_blank');"
    >
      <div class="text-center">
        <img src="/assets/images/panel_orig.png" />
        <h2>JOIN OUR PANEL</h2>
        <span>Participate in paid market research studies</span>
      </div>
    </div>
  </div>
</div>
<!-- end:Disclaimer -->
