<app-nav-bar></app-nav-bar>

<div class="app-contact container">
  <div calss="width-80" fxLayout="row" fxLayoutAlign="center start">
    <div
      class="network-button push-top"
      onclick="location.href='https://www.facebook.com/accelerantresearch';"
    >
      <img src="assets/images/facebook-logo.png" />
    </div>
    <div
      class="network-button push-top"
      onclick="location.href='https://twitter.com/Research_Dude';"
    >
      <img src="assets/images/twitter-logo.png" />
    </div>
    <div
      class="network-button push-top"
      onclick="location.href='https://www.linkedin.com/company/accelerant-research?trk=company_logo';"
    >
      <img src="assets/images/linkedin-logo.png" />
    </div>
    <div
      class="network-button push-top"
      onclick="location.href='https://www.youtube.com/user/AccelerantRschVideos';"
    >
      <img src="assets/images/youtube-logo.png" />
    </div>
    <div
      class="network-button push-top"
      onclick="location.href='mailto:info@accelerantresearch.com';"
    >
      <i class="material-icons">mail_outline</i>
    </div>
  </div>

  <h1 class="width-80 message-header">
    Contact us anytime with questions, comments, or requests. Fill out the
    contact form below or reach us at 704.206.8500 or
    <a
      onclick="location.href='mailto:info@accelerantresearch.com';"
      class="underline"
      >info@accelerantresearch.com</a
    >.
  </h1>

  <md-card-content>
    <form
      class="width-80"
      [formGroup]="contactForm"
      (ngSubmit)="submitContactForm(contactForm.value)"
      fxLayout="row"
      fxLayoutWrap
      #contactFormView="ngForm"
    >
      <md-input-container floatPlaceholder="auto" fxFlex="100%">
        <input
          mdInput
          name="name"
          type="text"
          required
          placeholder="Name"
          [formControl]="contactForm.controls['name']"
        />
        <md-error *ngIf="contactForm.controls['name'].hasError('required')">
          *Required
        </md-error>
      </md-input-container>
      <md-input-container floatPlaceholder="auto" fxFlex="100%">
        <input
          mdInput
          name="email"
          type="email"
          required
          placeholder="Email"
          [formControl]="contactForm.controls['email']"
        />
        <md-error *ngIf="contactForm.controls['email'].hasError('required')">
          *Required
        </md-error>
        <md-error *ngIf="contactForm.controls['email'].hasError('pattern')">
          *Enter a valid email
        </md-error>
      </md-input-container>

      <md-input-container floatPlaceholder="auto" fxFlex="100%">
        <input
          mdInput
          name="company"
          type="text"
          placeholder="Company"
          [formControl]="contactForm.controls['company']"
        />
      </md-input-container>

      <md-input-container
        floatPlaceholder="auto"
        fxFlex="100%"
        *ngIf="messageType == 'new'"
      >
        <input
          mdInput
          name="specs"
          type="text"
          placeholder="Project Specifications"
          [formControl]="contactForm.controls['specs']"
        />
      </md-input-container>

      <md-input-container floatPlaceholder="auto" fxFlex="100%">
        <textarea
          mdInput
          name="comments"
          placeholder="Comments"
          required=""
          md-maxlength="150"
          [formControl]="contactForm.controls['comments']"
        ></textarea>
      </md-input-container>

      <button
        md-raised-button
        class="btn btn-success push-bottom"
        type="submit"
        style="margin-left: 15px"
        [disabled]="!contactForm.valid"
      >
        SUBMIT
      </button>
    </form>
    <div *ngIf="submitted" class="submitted-box">
      Thank you, your information has been submitted and we will get back to you
      shortly.
    </div>
    <div *ngIf="error" class="error-box">
      Sorry we couldn't send your info, please try again later.
    </div>
  </md-card-content>

  <app-footer></app-footer>
</div>
