<div class="footer" *ngIf="!isLoggedIn">
  <div
    fxLayout="row"
    fxLayoutWrap
    fxFlexOffset="7%"
    fxLayoutAlign="start start"
    fxFlex="83%"
  >
    <!-- Services -->
    <div
      fxLayout="row"
      fxFlex="100%"
      fxFlex.gt-sm="30%"
      fxLayoutAlign="start start"
    >
      <!-- Company -->
      <div>
        <h3>COMPANY</h3>
        <ul>
          <ul>
            <li>
              <a md-button (click)="scrollTo('home')">Home</a>
            </li>
            <li>
              <a md-button (click)="scrollTo('about')">About Blognog</a>
            </li>
            <li>
              <a
                md-button
                href="http://surveys.accelerantresearch.com/s3/Accelerant-Research-Panel-Registration-Survey?RiD=ARDC"
                target="_blank"
                >Join Our Panel</a
              >
            </li>
            <li>
              <a md-button (click)="goToContact('general')">Contact Us</a>
            </li>
          </ul>
        </ul>
      </div>
    </div>
    <!-- end: Company -->

    <!-- Logo & disclaimer -->
    <div
      fxLayout="column"
      fxFlex="100%"
      fxFlexOffset.gt-sm="15%"
      fxFlex.gt-sm="55%"
      fxLayoutAlign="start end"
      class="text-right"
    >
      <div fxLayout="row" fxLayoutAlign="center start">
        <div
          class="network-button"
          onclick="location.href='https://www.facebook.com/accelerantresearch';"
        >
          <img src="assets/images/facebook-logo.png" />
        </div>
        <div
          class="network-button"
          onclick="location.href='https://twitter.com/Research_Dude';"
        >
          <img src="assets/images/twitter-logo.png" />
        </div>
        <div
          class="network-button"
          onclick="location.href='https://www.linkedin.com/company/accelerant-research?trk=company_logo';"
        >
          <img src="assets/images/linkedin-logo.png" />
        </div>
        <div
          class="network-button"
          onclick="location.href='https://www.youtube.com/user/AccelerantRschVideos';"
        >
          <img src="assets/images/youtube-logo.png" />
        </div>
        <div
          class="network-button"
          onclick="location.href='mailto:info@accelerantresearch.com';"
        >
          <i class="material-icons">mail_outline</i>
        </div>
      </div>
      <div>
        <img src="assets/images/accelerant-logo.jpg" />
      </div>
      <a
        md-button
        onclick="location.href='http://www.accelerantresearch.com/uploads/6/6/6/9/66692773/accelerant_research_privacy_policy.pdf';"
        >PRIVACY POLICY</a
      >
      <span class="copy-right"
        >ACCELERANT RESEARCH | 1242 MANN DRIVE, SUITE 100 | MATTHEWS, NC
        28105</span
      >
      <span class="copy-right">© COPYRIGHT {{year}}. ALL RIGHTS RESERVED.</span>
    </div>
    <!-- end: Logo & disclaimer -->

    <!-- <div fxFlex="100%" fxFlex.gt-sm="30%">
      <p>
        Blognog is a designed and registered trademark of Research & Accelerant Research INC.
      </p>
    </div> -->
  </div>
</div>

<div class="footer footer-admin" *ngIf="isLoggedIn">
  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
    <div fxFlex="100%" class="text-center tc-dark">
      <span> © {{year}} Blognog Research & Accelerant Research </span>
      <span> 1242 Mann Drive, Suite 100 </span>
      <span> Matthews, NC 28105, All Rights Reserved. </span>
    </div>
  </div>
</div>
