<div class="banner-container">
  <!--Banner-->
  <div
    fxFlex="100%"
    class="banner"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div
      fxLayout="column"
      class="tc-light banner-content"
      fxFlex="80%"
      fxLayoutAlign="center center"
    >
      <h1 color="accent" [innerHTML]="'BANNER.TITLE' | translate"></h1>
      <span class="message-title">{{ 'BANNER.SUB1' | translate }}</span>
      <span class="message-title push-bottom"
        >{{ 'BANNER.SUB2' | translate }}</span
      >
      <button
        md-button
        class="button-outline push-top"
        (click)="openLoginModal()"
      >
        {{'GET_STARTED' | translate}}
      </button>
    </div>
  </div>
  <!-- end: Banner -->

  <!--Home content-->
  <!-- <div fxLayout="row">
    <div fxLayout="row" fxFlex="100%" class="home-content" fxLayoutAlign="center center">
      <div fxFlex="80%" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxFlex="100%" fxFlex.gt-sm="20%">
          <img src="assets/images/responsive.png" alt="">
        </div>
        <div fxFlex="100%" fxFlex.gt-sm="80%" class="img-message">
          <h1>{{'HOME.TITLE' | translate}}</h1>
          <p class="md-title" [innerHTML]="'HOME.SUB' | translate">
            To express their innermost
            <b>feelings</b>,
            <b>attitudes</b>, and
            <b>opinions</b> about their relationships with different organizations and describe their real
            <b>life experiences</b>.
          </p>
          <button md-raised-button color="accent" class="button-rounded" (click)="openLoginModal()">
            <md-icon>message</md-icon>
            {{'VISIT' | translate}}
          </button>
        </div>
      </div>

      <div class="bottom-border">
        <div class="triangle-down-right "></div>
      </div>
    </div>
  </div> -->
  <!-- end:Home content-->
</div>
