<div class="bgc-primary tc-light login-title">
  <h2>Welcome to blognog</h2>
</div>
<div class="login-container">
  <form
    action=""
    fxLayoutGap="20px"
    fxLayout="row"
    fxLayoutWrap
    fxLayoutAlign="center center"
  >
    <md-input-container fxFlex="100%">
      <input
        [(ngModel)]="user.login"
        name="login"
        mdInput
        placeholder="Login"
        mdTextareaAutosize
      />
    </md-input-container>
    <md-input-container fxFlex="100%">
      <input
        [(ngModel)]="user.password"
        name="password"
        mdInput
        placeholder="Password"
        type="password"
      />
    </md-input-container>
    <div fxFlex="100%">
      <md-checkbox value="" color="accent">Remember me</md-checkbox>
    </div>
    <div fxLayout="row" *ngIf="isError">
      <div class="alert-warn">
        Authentication error. Please verify your login and password or project
        is not active.
      </div>
    </div>
    <button fxFlex="100%" md-raised-button color="accent" (click)="login()">
      Log in
    </button>
  </form>
</div>
