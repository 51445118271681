<!-->On !user session and greater than xs</!-->

<div class="mat-accent adviser"></div>

<md-toolbar class="nav-bar" *ngIf="!isLoggedIn" fxHide [fxShow.gt-xs]="true">
  <img
    src="assets/images/logo.png"
    alt=""
    width="<200></200>px"
    height="100%"
    routerLink="/"
  />
  <button md-button (click)="scrollTo('home')">Home</button>
  <button md-button (click)="scrollTo('about')">About</button>
  <button md-button (click)="scrollTo('buttons')">Get Started</button>
  <button
    md-button
    (click)="goToExternal('http://surveys.accelerantresearch.com/s3/Accelerant-Research-Panel-Registration-Survey?RiD=ARDC')"
  >
    Join Our Panel
  </button>
  <!-- <button md-button (click)="scrollTo('features')">Features</button> -->
  <button md-button (click)="goToContact('general')">Contact Us</button>
  <span> {{mediaSize}} </span>
  <div class="spacer"></div>
  <button md-button (click)="openLoginModal()">
    <md-icon class="md-24">lock</md-icon
    ><span [fxHide.lt-md]="true" style="display: inline">Login</span>
  </button>
</md-toolbar>
<!-->On !user session and less than small size</!-->
<md-toolbar class="nav-bar" *ngIf="!isLoggedIn" fxHide [fxShow.lt-sm]="true">
  <img
    src="assets/images/logo.png"
    alt=""
    width="<200></200>px"
    height="100%"
    routerLink="/"
  />
  <button md-button (click)="toggleLeftMenu()">
    <md-icon class="md-24">menu</md-icon>
  </button>
</md-toolbar>
<!-->Left menu open for none user session and small size </!-->
<div class="side-menu" *ngIf="!isLoggedIn " fxHide [fxShow.lt-sm]="true">
  <div
    class="shadow"
    (click)="toggleLeftMenu()"
    [@shadowState]="menuState"
  ></div>
  <div class="menu" [@menuState]="menuState">
    <img
      src="assets/images/logo.png"
      alt=""
      width="180px"
      height="100%"
      routerLink=""
    />
    <md-list dense>
      <md-divider class="light"></md-divider>
      <button fxFlexFill md-button (click)="scrollTo('home')">
        <div
          class="tc-base"
          fxLayoutGap="20px"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <md-icon class="md-24">home</md-icon>
          <span> Home </span>
        </div>
      </button>
      <button fxFlexFill md-button (click)="scrollTo('about')">
        <div
          class="tc-base"
          fxLayoutGap="20px"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <md-icon class="md-24">description</md-icon>
          <span> About </span>
        </div>
      </button>
      <button fxFlexFill md-button (click)="scrollTo('buttons')">
        <div
          class="tc-base"
          fxLayoutGap="20px"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <md-icon class="md-24">list</md-icon>
          <span> Get Started </span>
        </div>
      </button>
      <button fxFlexFill md-button (click)="scrollTo('buttons')">
        <div
          class="tc-base"
          fxLayoutGap="20px"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <md-icon class="md-24">list</md-icon>
          <span> Join Our Panel </span>
        </div>
      </button>
      <button fxFlexFill md-button (click)="goToContact('general')">
        <div
          class="tc-base"
          fxLayoutGap="20px"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <md-icon class="md-24">contact_mail</md-icon>
          <span> Contact Us </span>
        </div>
      </button>
      <button fxFlexFill md-button (click)="openLoginModal()">
        <div
          class="tc-base"
          fxLayoutGap="20px"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <md-icon class="md-24">lock</md-icon>
          <span> Login </span>
        </div>
      </button>
      <md-divider class="light"></md-divider>
    </md-list>
  </div>
</div>
<!-->Toolbar on user session </!-->
<!-->nav-bar class uses css primary color (green bar over nav-bar) </!-->
<md-toolbar class="nav-bar" *ngIf="isLoggedIn">
  <div class="pad-left" fxHide fxShow.gt-xs>
    <ng-content></ng-content>
  </div>
  <div class="spacer"></div>
  <img
    [src]="getUserAvatar(avatar)"
    class="avatar-image"
    height="52"
    width="52"
    crossorigin="anonymous"
  />
  <button md-button [mdMenuTriggerFor]="menuProfile">
    <div>{{userName}} <md-icon class="md-24">more_vert</md-icon></div>
  </button>
  <md-menu #menuProfile="mdMenu" [overlapTrigger]="false">
    <button
      *ngIf="checkAccess([UserRole.ADMIN])"
      md-menu-item
      (click)="openCreateModeratorModal()"
    >
      <md-icon>group_add</md-icon>
      <span>Add New Moderator</span>
    </button>
    <button md-menu-item (click)="logOut()">
      <md-icon>exit_to_app</md-icon>
      <span>{{'LOG_OUT' | translate}}</span>
    </button>
  </md-menu>
</md-toolbar>
