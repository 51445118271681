<div fxLayout="row" fxFlexFill>
  <!-- To hide menu when small, also uncomment sidenav-fixed <div [fxFlex]="size" [fxFlex.lt-md]="isMenuOpened?size:0" class=" sidenav mat-primary" class.lt-md="mat-primary sidenav sidenav-fixed"> -->
  <div
    [fxFlex]="size"
    class="sidenav mat-primary"
    class.lt-md="mat-primary sidenav sidenav-fixed"
  >
    <div class="sidenav-header">
      <div>
        <button
          md-button
          (click)="onClickToggleMenu()"
          fxLayoutAlign="start center"
        >
          <md-icon class="md-24">menu</md-icon>
        </button>
      </div>
      <md-divider class="light"></md-divider>
      <div>
        <img src="assets/images/logo.png" alt="" *ngIf="isMenuOpened" />
        <!-- This icon is only to show if have a small version of logo -->
        <!-- <md-icon class="md-24 pad" *ngIf="!isMenuOpened">forum</md-icon> -->
      </div>
    </div>
    <md-divider class="light"></md-divider>
    <div class="sidenav-content">
      <md-list dense>
        <!-- Main Theme (project) image -->
        <div
          *ngIf="theme.name != 'Default' && theme.type == '1'"
          class="text-center"
        >
          <img src="{{theme.image?.url}}" crossorigin="anonymous" />
          <md-divider class="light"></md-divider>
        </div>
        <!-- end: Main Theme image-->

        <!--Main Navigation elements</!-->
        <h3 class="tc-base pad-left" *ngIf="isMenuOpened">
          {{'MAIN.TITLE' | translate}}
        </h3>
        <a
          fxFlexFill
          md-button
          *ngFor="let nav of navMainItems"
          (click)="closeMenu()"
          routerLink="{{nav.url}}"
        >
          <div
            class="tc-base"
            fxLayoutGap="20px"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <md-icon class="md-24 {{theme.contrast}}">{{nav.icon}}</md-icon>
            <span *ngIf="isMenuOpened">{{nav.text | translate}}</span>
          </div>
        </a>
        <!--end: Main Navigation elements</!-->

        <md-divider class="light"></md-divider>

        <!--Forums Navigation elements</!-->
        <h3 class="tc-base pad-left" *ngIf="isMenuOpened">
          {{'FORUM.TITLE' | translate}}
        </h3>
        <a
          fxFlexFill
          md-button
          *ngFor="let nav of navForumItems"
          (click)="closeMenu()"
          routerLink="{{nav.url}}"
        >
          <div
            class="tc-base"
            fxLayoutGap="20px"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <md-icon class="md-24 {{theme.contrast}}">{{nav.icon}}</md-icon>
            <span *ngIf="isMenuOpened">{{nav.text | translate}}</span>
          </div>
        </a>
        <!--Forums Navigation elements</!-->

        <md-divider class="light"></md-divider>
      </md-list>
    </div>
  </div>
  <div fxFlex="auto" class="overflow-hidden">
    <div class="router">
      <div
        class="shadow"
        [fxShow.lt-md]="isMenuOpened"
        fxHide
        (click)="closeMenu()"
      ></div>
      <div class="router-wrapper">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
