<app-nav-bar></app-nav-bar>

<div class="container" style="margin-top: -1px">
  <div class="main-view-container" #landing id="landing">
    <landing-home id="home" #home></landing-home>
    <landing-about-2 id="about" #about></landing-about-2>
    <!-- <landing-about id="about" #about></landing-about> -->
    <landing-buttons id="buttons" #buttons></landing-buttons>
    <!-- <landing-features id="features" #features ></landing-features> -->
    <!-- <landing-contact id="contact" #contact ></landing-contact> -->
  </div>
  <app-footer></app-footer>
</div>
